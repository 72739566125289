<div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <p-toast key="tst" [baseZIndex]="99999"></p-toast>

    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
        <div class="col-12 mt-5 xl:mt-0 text-center">
            <img src="assets/images/fcegg.png" alt="Sakai logo" class="mb-5" width="150px">
        </div>
        <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                <div class="text-center mb-5">
                    <div class="text-900 text-3xl font-medium mb-3">Hallo!</div>
                    <span class="text-600 font-medium">Melde dich an!</span>
                </div>
            
                <div class="w-full md:w-10 mx-auto">
                    <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                    <input id="email1" type="text" [(ngModel)]="email" placeholder="E-Mail-Adresse" pInputText class="w-full mb-3" style="padding:1rem;">
            
                    <button pButton pRipple label="Anmelden" class="w-full p-3 text-xl" (click)="login()"></button>
                </div>
            </div>
        </div>
    </div>
</div>