<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{appMain.config.dark ? 'logo-white' : 'logo-dark'}}.svg" alt="logo"> -->
        <span>FC BRAUEREI EGG</span>
    </a>

    <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
        <i class="pi pi-bars"></i>
    </a>

    <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
        <i class="pi pi-ellipsis-v"></i>
    </a>

    <div class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active':appMain.topMenuActive}">
        <a class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </a>
    </div>
</div>