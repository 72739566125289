import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service'

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authService: AuthService) {

  }

  canActivateChild() {

    if (!this.authService.getUser()) {
      this.router.navigateByUrl('/login');
      return false;
    }

    return true;
  };

  canActivate() {

    if (!this.authService.getUser()) {
      this.router.navigateByUrl('/login');
      return false;
    }

    return true;
  };

}
