import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financial-year',
  templateUrl: './financial-year.component.html',
  styleUrls: ['./financial-year.component.scss']
})
export class FinancialYearComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
