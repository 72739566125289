<div class="grid p-fluid">
    <div class="col-12">
        <div class="card card-w-title">
            <h5>MenuBar</h5>
            <p-menubar [model]="tieredItems">
                <ng-template pTemplate="end">
                    <span class="p-input-icon-right">
                        <input type="text" pInputText placeholder="Search">
                        <i class="pi pi-search"></i>
                    </span>
                </ng-template>
            </p-menubar>
        </div>
    </div>

    <div class="col-12">
        <div class="card card-w-title">
            <h5>Breadcrumb</h5>
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home'}"></p-breadcrumb>
        </div>
    </div>

    <div class="col-12">
        <div class="card card-w-title flex flex-wrap">
            <div class="col-12 md:col-8 h-full">
                <h5>TabMenu</h5>
                <p-tabMenu styleClass="mb-5" [model]="routeItems"></p-tabMenu>

                <h5>Steps</h5>
                <p-steps [model]="routeItems" styleClass="mt-5" [readonly]="false"></p-steps>
            </div>
            <div class="col-12 md:col-4 mt-5 md:mt-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4">
        <div class="card card-w-title">
            <h5>TieredMenu</h5>
            <p-tieredMenu [model]="tieredItems"></p-tieredMenu>
        </div>
    </div>

    <div class="col-12 md:col-4">
        <div class="card card-w-title">
            <h5>Plain Menu</h5>
            <p-menu [model]="plainMenuItems"></p-menu>
        </div>
    </div>

    <div class="col-12 md:col-4">

        <div class="card card-w-title">
            <h5>Overlay Menu</h5>

            <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
            <button type="button" pButton icon="pi pi-chevron-down" label="Options" (click)="menu.toggle($event)" style="width:auto"></button>
        </div>

        <div class="card card-w-title" #anchor>
            <h5>ContextMenu</h5>
            Right click to display.
            <p-contextMenu [target]="anchor" [model]="menuItems"></p-contextMenu>
        </div>
    </div>

    <div class="col-12 md:col-6">
        <div class="card card-w-title">
            <h5>MegaMenu - Horizontal</h5>
            <p-megaMenu [model]="megaMenuItems"></p-megaMenu>

            <h5>MegaMenu - Vertical</h5>
            <p-megaMenu [model]="megaMenuItems" orientation="vertical" [style]="{'width':'200px'}"></p-megaMenu>
        </div>
    </div>

    <div class="col-12 md:col-6">
        <div class="card card-w-title">
            <h5>PanelMenu</h5>
            <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
        </div>
    </div>
</div>
