import { Component, OnDestroy } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AuthService } from './shared/services/auth/auth.service';
import { RouteConfigLoadEnd, Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items: MenuItem[];

    constructor(public appMain: AppMainComponent, private authService: AuthService, private router: Router) { }

    public logout() {
        this.authService.signOut();
        this.router.navigateByUrl('/login');
    }
}
