import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/api/appconfig';
import { ConfigService } from 'src/app/services/app.config.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit, OnDestroy {

  public valCheck: string[] = ['remember'];
  public email: string;
  public config: AppConfig;
  public subscription: Subscription;

  constructor(public configService: ConfigService, private authService: AuthService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe(config => {
      this.config = config;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public login() {
    this.authService.signIn(this.email).then((response) => {
      if (!response.error) {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Email versendet', detail: 'Link zum Einloggen wurden an deine E-Mail-Adresse verschickt' });
      } else {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Falscher Benutzer', detail: 'Benutzer wurde nicht gefunden' });
      }
    }).catch((error) => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Falscher Benutzer', detail: 'Benutzer wurde nicht gefunden' });
    });
  }

}
